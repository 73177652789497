import React from "react";
import { Button, Card, Row, Col } from "react-bootstrap";

import photoCard2 from "../../assets/images/promoCards/asset5.png";
import photoCard1 from "../../assets/images/promoCards/asset1.png";
import photoCard3 from "../../assets/images/promoCards/asset4.png";
import photoCard4 from "../../assets/images/promoCards/asset3.png";
import photoCard5 from "../../assets/images/promoCards/asset2.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function PromoCards() {
  const cardContent = [
    {
      image: photoCard1,
      title: "desde $105.728",
      promo: "Aceite y Filtro",
      /* text: "Modelos entre 2013 al 2017.", */
    },
    {
      image: photoCard5,
      title: "desde $208.597",
      promo: "Batería",
      /* text: "Modelos entre 2013 al 2017.", */
    },
    {
      image: photoCard2,
      title: "desde $161.648",
      promo: "Neumáticos",
      /* text: "Modelos entre 2013 al 2017.", */
    },

    {
      image: photoCard3,
      title: "desde $150.414",
      promo: "Frenos",
      /* text: "Modelos entre 2013 al 2017.", */
    },
    {
      image: photoCard4,
      title: "desde $221.160",
      promo: "Suspensión",
      /* text: "Modelos entre 2013 al 2017.", */
    },
  ];

  return (
    <Row className="m-0 p-0 my-3 justify-content-center row-cols-2 row-cols-md-3 row-cols-xl-5">
      {cardContent.map((content, i) => (
        <a
          href={`https://wa.me/5491145058787?text=Hola, me deseo reservar un turno para realizar el cambio de ${content.promo.toLowerCase()}:%20`}
          target="_blank"
          rel="noreferrer"
          key={i}
        >
          <Col className="my-3">
            <Card style={{ border: "0px" }}>
              <Card.Img variant="top" src={content.image} />
              <Card.Body
                className="bg-white m-0 shadow text-center px-1"
                style={{
                  borderRadius: "5px",
                  position: "relative",
                  top: "10px",
                }}
              >
                <Card.Title style={{ color: "black", fontWeight: "bold" }}>
                  {content.title}
                </Card.Title>
                <Card.Text>
                  {/* <Row>
                    <Col style={{ fontSize: "2vh" }}>
                      <b>{content.promo}</b>
                    </Col>
                  </Row> */}
                  <Row>
                    <Col style={{ fontSize: "2vh" }}>{content.text}</Col>
                  </Row>
                </Card.Text>

                <Button variant="danger">
                  <FontAwesomeIcon
                    icon="fa-brands fa-whatsapp"
                    className="mr-2"
                  />
                  <b>Agendar Turno</b>
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </a>
      ))}
    </Row>
  );
}
